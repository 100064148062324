import { Injectable, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { from} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RequestsService implements  OnInit {

    public platform: string;

    constructor(
        private HTTPIonic: HTTP,
        private HTTPAngular: HttpClient,
        private plt: Platform,
    ) { 
        if(plt.is('cordova')){
            //если телефон
            this.platform = 'cordova';
        }
    }
    

    ngOnInit(){
    }

    getDataHttp(url){
        console.log(url);
        return new Promise((resolve, reject)=>{
            if(this.platform == 'cordova'){
                this.HTTPIonic.get(url, {}, {}).then(data => {
                    console.log(data);
                    let dataJson = JSON.parse(data.data); 
                    
                    resolve(dataJson);
                }).catch(error => {
                    console.log(error);
                    reject('NoInternet');
                });
            } else {    
                this.HTTPAngular.get(url).subscribe(
                    data => {
                      resolve(data);
                      
                    },
                    () => reject('NoInternet')                    
                );
            }
        });
    }

    postDataHttp(url, data={}, headers={}){
        // console.log("post");

        if (headers == {}){
          headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Content-length':  + JSON.stringify(data).length,
          };
        }
    
        return new Promise((resolve, reject)=>{

            if(this.platform == 'cordova'){
                this.HTTPAngular.post(url, JSON.stringify(data), headers)
                .subscribe(
                    result => {

                        resolve(result);
                    } ,
                    () => reject('NoInternet') 
                );
    
            } else {    
                console.log("post");

                console.log(url);
                this.HTTPAngular.post(url, data).subscribe(    
                    data => {
         
                        console.log(data);
                        resolve(data);
                    } ,
                    () => reject('NoInternet')                    
                );
            }
        });
      }
    

  
}