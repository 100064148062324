import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'apartament-list',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'apartament-list',
    loadChildren: () => import('./apartament-list/apartament-list.module').then( m => m.ApartamentListPageModule)
  },
  {
    path: 'work',
    loadChildren: () => import('./work/work.module').then( m => m.WorkPageModule)
  },
  {
    path: 'work-list',
    loadChildren: () => import('./work-list/work-list.module').then( m => m.WorkListPageModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('./setting/setting.module').then( m => m.SettingPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'private-policy',
    loadChildren: () => import('./privacy_policy/privacy_policy.module').then( m => m.PrivacyPolicyPageModule)
  },

  {
    path: 'refund',
    loadChildren: () => import('./refund/refund.module').then( m => m.RefundPageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'search-pay',
    loadChildren: () => import('./search-pay/search-pay.module').then( m => m.SearchPayModule)
  },
  {
    path: 'accounting',
    loadChildren: () => import('./accounting/accounting.module').then( m => m.AccountingPageModule)
  },
  {
    path: 'generation-key',
    loadChildren: () => import('./generation-key/generation-key.module').then( m => m.GenerationKeyModule)
  },
  {
    path: 'response-busy',
    loadChildren: () => import('./response-busy/response-busy.module').then( m => m.ResponseBusyPageModule)
  },
  {
    path: 'dogovor',
    loadChildren: () => import('./dogovor/dogovor.module').then( m => m.DogovorPageModule)
  },
  {
    path: 'dogovor-form',
    loadChildren: () => import('./dogovor-form/dogovor-form.module').then( m => m.DogovorFormPageModule)
  },
  {
    path: 'chess',
    loadChildren: () => import('./chess/chess.module').then( m => m.ChessPageModule)
  },
  {
    path: 'kkm',
    loadChildren: () => import('./kkm/kkm.module').then( m => m.KkmPageModule)
  },
  {
    path: 'create-bron',
    loadChildren: () => import('./create-bron/create-bron.module').then( m => m.CreateBronPageModule)
  },

  
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
