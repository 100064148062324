import { Injectable, OnInit } from '@angular/core';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';

import { CookieService } from 'ngx-cookie-service';
import { RequestsService } from '../service/requests.service';


import { from} from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService implements  OnInit {
  public hystoryListApartaments: any;
  public realtyDataApartaments: any;
  public realtyDataApartamentsGroup: any;

  public countDayBron = 1;
  public zalog = 1000;
  public testZalog = 1;

  public user: any = {
    name: '',
    password: '',
    status: '',
    phone: '',
    email: '',
    city: ''
  };

  public city = {
    "Москва": 1,
    "Нижний Новгород": 7
  };

  public todo: any = {
    dateStart: moment().format('YYYY-MM-DD'),
    dateFinish: moment().add(1, 'day').format('YYYY-MM-DD'),
    date: 1,
    city: 7,
    addres: '',
    apartament: {},
    textWork: ''
  };


  public themes: any = {
    green: {
      background: '#0ad740',
      color: '#dbff54'
    },
    black: {
      background: '#000000',
      color: '#dbff54'
    },
    hotPink: {
      background: '#FF69B4',
      color: '#dbff54'
    },
    royalBlue: {
      background: '#4682B4',
      color: '#dbff54'
    },
    dimGray: {
      background: '#696969',
      color: '#dbff54'
    },
    mediumPurple: {
      background: '#9370DB',
      color: '#dbff54'
    },
  };

  public mythemes = 'green';

  constructor(
    private nativeStorage: NativeStorage,
    private cookies: CookieService,
    private request: RequestsService
  ) {}

  ngOnInit(){
    this.getApartaments();
  }

  getZalog(){
    if(this.user.phone === '9200007276'){
      return this.testZalog;
    } else {
      return this.zalog;
    }
  }

  apiRequest(workData){
    return new Promise((resolve, reject)=>{

      const url = 'https://api2.golden-clover.ru/';
      const headers = {
          'Content-Type': 'application/json'
      };

      console.log(workData);

      console.log(url);

      from(this.request.postDataHttp(url,  JSON.stringify(workData) , headers))
      .subscribe(
        (data) => {
          // console.log(data);
          // console.log(data['data']['staus']);

          if(data['result'] === 'ok'){

            resolve(data['data']);


          } else {
            reject(data);
          }

        },
        () => {},
        () => {},
      );

    });

  }


  getApartaments(){
    const url = 'https://api2.golden-clover.ru/';
    const headers = {
        'Content-Type': 'application/json'
    };


    const workData = {
        modules: 'selectApartament',
        param: {
        }
    };

    // console.log(workData);

    from(this.request.postDataHttp(url,  JSON.stringify(workData) , headers))
    .subscribe(
      (data) => {
        this.realtyDataApartaments = data["data"];

        // console.log(this.realtyDataApartaments);

        this.groupApartament();
        // console.log(data);

      },
      () => {},
      () => {},
    );
  }


  groupApartament(){

    const apartamentGroup: any = {};

    for(let inx in this.realtyDataApartaments){

      if(apartamentGroup[this.realtyDataApartaments[inx].city.id] == undefined){
        apartamentGroup[this.realtyDataApartaments[inx].city.id] = {};
      } 

      if(apartamentGroup[this.realtyDataApartaments[inx].city.id][this.realtyDataApartaments[inx].address] == undefined ){
        apartamentGroup[this.realtyDataApartaments[inx].city.id][this.realtyDataApartaments[inx].address] = [];
      }

      apartamentGroup[this.realtyDataApartaments[inx].city.id][this.realtyDataApartaments[inx].address].push(this.realtyDataApartaments[inx]);
      
    }

    this.realtyDataApartamentsGroup = apartamentGroup;

  }




}
