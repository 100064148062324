import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';

import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { HttpClientModule } from '@angular/common/http';

import { RemoveCommaPipeModule } from './pipe/remove-comma/remove-comma.pipe.module';
import { ReversePipeModule } from './pipe/reverce/reverce.pipe.module';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

import { ImageModalPageModule } from './image-modal/image-modal.module';

import { FormsModule } from '@angular/forms';
import { Storage } from '@ionic/storage-angular';
import { CookieService } from 'ngx-cookie-service';

// import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';

import { ClipboardModule } from 'ngx-clipboard';


@NgModule({
    declarations: [
        AppComponent,
        // RemoveCommaPipe
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        RemoveCommaPipeModule,
        ReversePipeModule,
        ImageModalPageModule,
        FormsModule,
        ClipboardModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        HTTP,
        NativeStorage,
        InAppBrowser,
        Storage,
        CookieService,
        // Clipboard
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
