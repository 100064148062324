import { Component, OnInit  } from '@angular/core';
import { Platform } from '@ionic/angular';
import { DatabaseService } from './service/database.service';
import { CookieService } from 'ngx-cookie-service';
import { RequestsService } from './service/requests.service';
import { from} from 'rxjs';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  public acaunt: boolean = false;

  public appPagesAllUser = [
    { title: 'Апартаменты', url: '/apartament-list', icon: 'search' },
    { title: 'Наши контакты', url: '/contact', icon: 'call' },
    { title: 'Правила проживания', url: '/info', icon: 'information'},
    { title: 'Политика конфиденциальности', url: '/private-policy', icon: 'lock-closed'},

  ];

  public appPagesRoot = [
    { title: 'Пользователи', url: '/work', icon: 'hammer' },
  ];


  public appPagesAdmin = [
    { title: 'Создать бронь', url: '/create-bron', icon: 'create' },
    { title: 'Возврат залогов', url: '/refund', icon: 'card' },
    { title: 'Генерация кодов', url: '/generation-key', icon: 'key' },
    { title: 'Поиск броней', url: '/search-pay', icon: 'reader' },
    // { title: 'Пробитие чеков', url: '/kkm', icon: 'wallet' },
    { title: 'Шахматка', url: '/chess', icon: 'grid' },
    
    // { title: 'Занятые номера', url: '/response-busy', icon: '' },

    // { title: 'Договора', url: '/dogovor', icon: '' },
    // { title: 'Постановка задачи', url: '/work', icon: 'hammer' },
    // { title: 'Список задач', url: '/work-list', icon: 'construct' },
    // { title: 'Бюджетирование', url: '/accounting', icon: 'calculator' },

    
  ];

  public appPages = [
    { title: 'История бронирований', url: '/history', icon: 'checkmark' },
    { title: 'Настройки', url: '/setting', icon: 'settings' },
  ];

  public labels = [];

  public currentUser: any;

  constructor(
    private database: DatabaseService,
    private cookies: CookieService,
    private request: RequestsService
    
  ) {
  }

  ngOnInit(){

    console.log(this.cookies.get('city'));
    this.database.user.phone = this.cookies.get('phone');
    this.database.user.status = this.cookies.get('status');
    this.database.user.city = this.cookies.get('city');

    this.database.todo['city'] = this.cookies.get('city');

    if(this.database.user.city == 0){
      this.database.todo.city = 7;
    } else {
      this.database.todo.city = this.database.user.city;
    }

    if(this.database.user.phone != '' && this.database.user.status != '') {
      this.acaunt = true;
    }

  }

  activeAcaunt() {

  

    // if(this.database.user.phone == "89200007276" ){

    //   // this.database.user.status = "admin";
    // } else {
    //   // this.database.user.status = "user";
    // }

    console.log(this.database.user);


    let url = "https://api2.golden-clover.ru/";
    let headers = {
        'Content-Type': 'application/json'
    };


    let workData = {
        "modules": "getUser",
        "param": {
          'phone': ['=', this.database.user.phone],
          'password': ['=', this.database.user.password]
        }
    }; 

    console.log(workData);

    from(this.request.postDataHttp(url,  JSON.stringify(workData) , headers))
    .subscribe(
      (data) => { 
        console.log(data);
        // console.log(data['data']['staus']);

        if(data['result'] == "ok"){
          this.cookies.set('phone', data['data']['phone']);
          this.cookies.set('status', data['data']['status']);
          this.cookies.set('city', data['data']['city']);
          
          this.database.user.phone = data['data']['phone'];
          this.database.user.status = data['data']['status'];
          this.database.user.city = data['data']['city'];

          

          if(data['data']['city'] != 0){
            this.database.todo.city = data['data']['city'];
          } 


          // console.log(this.database.user);

          this.acaunt = true;

        } else {

        }

      },
      () => {},
      () => {},
    );
    
  }

  exit(){

    this.database.user.phone = "";
    this.database.user.status = "";
    this.acaunt = false;

    this.cookies.set('phone', "");
    this.cookies.set('status', "");

  }

}

