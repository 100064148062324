import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NavParams, ModalController, IonSlides } from '@ionic/angular';
import { DatabaseService } from '../service/database.service';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.page.html',
  styleUrls: ['./image-modal.page.scss'],
})
export class ImageModalPage implements OnInit {
  // @ViewChild('slider', { read: ElementRef }) slider: ElementRef;
  @ViewChild(IonSlides) slides: IonSlides;
  @Input('dataImg')dataImg: any;
  @Input('inxPhoto')inxPhoto: any;

  sliderOpts: any = {
    zoom: true
  };
  
  constructor(
    private navParams: NavParams,
    public modalCtrl: ModalController,
    private database: DatabaseService,
  ) { }

  ngOnInit() {
    this.sliderOpts = {
      initialSlide: this.inxPhoto,
      zoom: true
    };
  }

  ionViewDidEnter(){
    this.slides.update();
  }


  async zoom(zoomIn: boolean){
    const slider = await this.slides.getSwiper();
    const zoom = slider.zoom;
    zoomIn ? zoom.in() : zoom.out();
  }

  close(){
    this.modalCtrl.dismiss();
  }

}
